// Actions are payloads of informations that are used to modify the
// application's Redux state. They may also act as signals and information
// payloads to sagas when asynchronous operations need to occur. This app uses
// [Flux Standard Actions](https://github.com/acdlite/flux-standard-action) to
// make sure actions have a uniform schema.
//
// http://redux.js.org/docs/basics/Actions.html
import { createAction } from "redux-actions";
import { upperSnakeCase } from "./utils";
import camelCase from "lodash/camelCase";

// The action type names for this app's Redux/Flux actions. Used to generate
// `ActionTypes` and `Actions` used throughout the app.
// actionName -> ActionType ('action name' -> 'ACTION_NAME')
// actionName -> ActionCreator ('action name' -> 'actionName')
let actionNames = normalize(
  // Data load resource actions
  "onboarding step change",
  "handle onboarding back",
  "toggle sidebar",
  "toggle timeline",
  "set current path",
  "handle errors",
  "profile image",
  "set step number",
  ...loadAll(
    "user info by object id",
    "timelines",
    "default timeline",
    "step info",
    "profile image",
    "step professionals",
    "professionals",
    "answered questions",
    "summary",
    "documents",
    "document types",
    "professionals categories",
    "professional details",
    "payment intent",
    "professional settings"
  ),
  ...doAll(
    "save onboarding step",
    "save timeline",
    "create timeline",
    "login",
    "refresh token",
    "logout",
    "show error message",
    "show message",
    "save note",
    "save step",
    "hire professional",
    "save external professional",
    "mark step completed",
    "save detail fields",
    "save user info",
    'onboarding login',

    "save summary",
    "get clients",
    "get leads",
    "get client timeline",
    "close client timeline",
    "upload document",
    "update document",
    "delete document",
    "save professional details",
    "save stripe customer",
    "save profile image",
    "save professional settings"
  )
);

// Create multiple load action types (get, loading and loading)
function loadAll(...names) {
  return [].concat(...names.map(load));
}

// Create multiple load action types (do, doing and done)
function doAll(...names) {
  return [].concat(...names.map(doit));
}

// Create load action type variants with do, doing and done prefixed
function doit(resourceName) {
  return [
    `do ${resourceName}`,
    `doing ${resourceName}`,
    `done ${resourceName}`,
    `error ${resourceName}`,
  ];
}

// Create load action type variants with get, loading and loading prefixed
function load(resourceName) {
  return [
    `get ${resourceName}`,
    `loading ${resourceName}`,
    `loaded ${resourceName}`,
    `error loading ${resourceName}`,
  ];
}

// Uper snake case names
function normalize(...names) {
  return names.map(upperSnakeCase);
}

// The app's redux action types. Each key is the same as the string action type.
// For each action name, the type is generated by upper snake-casing the phrase.
// assert.equal(ActionTypes.EAT_CAKE, 'EAT_CAKE');
export const ActionTypes = actionNames.reduce((actionTypes, actionName) => {
  actionTypes[actionName] = actionName;
  return actionTypes;
}, {});

// Action creators for the app (functions that return Redux/Flux actions).
// let action = Actions.wingardiumLeviosa('ferret');
// assert.deepEqual(action, { type: 'WINGARDIUM_LEVIOSA', payload: 'ferret' });
export const Actions = actionNames.reduce((actions, actionName) => {
  let actionCreatorName = camelCase(actionName);
  actions[actionCreatorName] = createAction(
    actionName,
    (x) => x,
    (x, y) => y
  );
  return actions;
}, {});
