/* global io */

const apiUrl = process.env.REACT_APP_API_URL;

/**
 * parseToJsonPromise
 * Returns JSON result for the fetch response
 * e.g. parseToJsonPromise(data) => { ...values }
 *
 **/

function parseToJsonPromise(data) {
  return new Promise((res) => {
    data
      .json()
      .then((object) => {
        let response = {
          successful: [200, 201].includes(data.status),
          data: object,
          code: data.status,
        };
        return res(response);
      })
      .catch((error) => {
        res({
          successful: false,
          data: undefined,
          code: data.status,
        });
      });
  });
}

const apiGenerator = (url, uri, method, payload, type) => {
  const guid = localStorage.getItem("guid");
  const accessToken = localStorage.getItem("access_token");

  let headers = {
    Accept: "application/json",
  };

  if (guid) {
    headers["guid"] = guid;
  }

  if (accessToken) {
    headers["Authorization"] = `Bearer ${accessToken}`;
  }

  if (type !== "file") {
    headers["Content-Type"] = "application/json";
    payload = JSON.stringify(payload);
  }

  return fetch(`${url}${uri}`, {
    method,
    body: payload,
    headers,
  })
    .then(parseToJsonPromise)
    .catch((error) => {
      return { successful: false, data: undefined, code: error.status_code };
    });
};

const ApiGenerator = (uri, method, payload, type) => {
  return apiGenerator(apiUrl, uri, method, payload, type);
};

const networkAdapter = (generator) => {
  return {
    POST: (url, payload, type) => generator(url, "POST", payload, type),
    PUT: (url, payload) => generator(url, "PUT", payload),
    DELETE: (url) => generator(url, "DELETE"),
    GET: (url, token, payload) => generator(url, "GET", payload),
  };
};

/**
 *
 * @type {{POST: ((p1?:*, p2?:*)), PUT: ((p1?:*, p2?:*)), DELETE: ((p1?:*)), GET: ((p1?:*))}}
 * @private
 */

const _requestAPI = networkAdapter(ApiGenerator);

// Clients Timeline
export const getProClients = (guid) =>
  _requestAPI.GET("/list-professional-customers/" + guid);
export const getClientTimeline = (UserObjectId) =>
  _requestAPI.GET("/get-professional-client-timeline/" + UserObjectId);

export const loadTimelines = (payload) =>
  _requestAPI.GET(
    `/timelines/${localStorage.getItem("selectedClientUserObjectId")}`,
    payload
  );
export const loadDefaultTimeline = (payload) =>
  _requestAPI.GET(`/default-timeline`, payload);

export const loadStepInfo = (payload) =>
  _requestAPI.POST(
    `/step-info/${localStorage.getItem("selectedClientUserObjectId")}`,
    payload
  );

export const loadStepProfessionals = (payload) =>
  _requestAPI.POST(
    `/step-professionals/${localStorage.getItem("selectedClientUserObjectId")}`,
    payload
  );
export const saveStep = (payload) =>
  _requestAPI.POST(
    `/save-step/${localStorage.getItem("selectedClientUserObjectId")}`,
    payload
  );
export const hireProfessional = (payload) =>
  _requestAPI.POST(
    `/hire-professional/${localStorage.getItem("selectedClientUserObjectId")}`,
    payload
  );
export const saveExternalProfessional = (payload) =>
  _requestAPI.POST(
    `/save-external-professional/${localStorage.getItem(
      "selectedClientUserObjectId"
    )}`,
    payload
  );

// User
export const userInfoByObjectId = (guid) =>
  _requestAPI.GET(
    "/get-user-info-by-object-id?guid=" + encodeURIComponent(guid)
  );
export const saveOnboardingStep = (payload) =>
  _requestAPI.POST("/save-professional", payload);
export const saveUserInfo = (payload) =>
  _requestAPI.POST("/save-all-user-info", payload);
export const answeredQuestions = (payload) =>
  _requestAPI.GET("/get-answered-questions", payload);
export const saveStripeCustomer = (payload) =>
  _requestAPI.POST("/create-stripe-customer", payload);

// Auth
export const login = (payload) =>
  _requestAPI.POST("/login-professional", payload);
export const refreshToken = (payload) =>
  _requestAPI.POST("/refresh-token", payload);

// Professionals APIs
export const loadProfessionals = (payload) =>
  _requestAPI.POST("/professionals", payload);
export const loadProfessionalsCategories = (payload) =>
  _requestAPI.GET("/professionals-categories", payload);
export const loadProfessionalDetails = (payload) =>
  _requestAPI.POST("/get-professional", payload);
export const saveProfessionalDetails = (payload) =>
  _requestAPI.POST("/save-professional", payload);
export const loadProfessionalSettings = () =>
  _requestAPI.POST("/get-professional-settings");
export const saveProfessionalSettings = (payload) =>
  _requestAPI.POST("/save-professional-settings", payload);

// Summary APIs
export const loadSummary = (payload) =>
  _requestAPI.POST("/get-summary", payload);
export const saveSummary = (payload) =>
  _requestAPI.POST("/save-summary", payload);

// Document APIs
export const uploadDocument = (payload) =>
  _requestAPI.POST("/upload-document", payload);
export const loadDocuments = (payload) =>
  _requestAPI.POST("/get-documents", payload);
export const loadDocumentTypes = (payload) =>
  _requestAPI.GET("/get-document-types", payload);
export const updateDocument = (payload) =>
  _requestAPI.POST("/update-document", payload);
export const deleteDocument = (payload) =>
  _requestAPI.POST("/delete-document", payload);

export const saveProfilePhoto = (payload) =>
  _requestAPI.POST("/save-profile-pic", payload);
export const requestVerifyEmail = (payload) =>
  _requestAPI.POST("/request-verify-email", payload);

// Stripe APIs
export const loadPaymentIntent = (payload) =>
  _requestAPI.POST("/create-payment-intent", payload);
