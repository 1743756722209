import React from 'react';
import NavLink from "./NavLink";

const AccountOptions = ({ currentPath, auth, handleLogout }) => {
  return (
    <div className={"account-options"}>
      <NavLink icon={'support'} label='Contact us' path="/contact-us" currentPath={currentPath} />
      <NavLink label='Log out' onClick={handleLogout} />
    </div>
  );
};

export default AccountOptions;
