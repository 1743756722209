import React from 'react';
import {
  Col
} from "react-bootstrap";
import Layout from "../../components/Layout"
import HomebuyerCard from "../../components/HomebuyerCard"
import Link from "../../utils/Link";
import styles from './ProfileHomebuyer.module.scss'

const HomebuyerProfile = (props) => {

  return <Layout title="Public profile (viewing as home buyer)" classes={{row: styles.row}}>

    <Col xs={12} className="text-right mb-2">
      <Link to="/profile">
        View as professional
      </Link>
    </Col>
    <HomebuyerCard xs={12} {...props}/>
  </Layout>


};

export default HomebuyerProfile;
