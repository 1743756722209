import {call, put, select} from 'redux-saga/effects';
import {Actions, ActionTypes} from '../actions';
import {loadProfessionals, loadTimelines, loadProfessionalsCategories, loadProfessionalDetails, saveProfessionalDetails, loadProfessionalSettings, saveProfessionalSettings} from './networks';
import _ from "underscore";

export default [
    [ActionTypes.GET_PROFESSIONALS, getProfessionals],
    [ActionTypes.GET_PROFESSIONALS_CATEGORIES, getProfessionalsCategories],
    [ActionTypes.GET_PROFESSIONAL_DETAILS, getProfessionalDetails],
    [ActionTypes.DO_SAVE_PROFESSIONAL_DETAILS, doSaveProfessionalDetails],
    [ActionTypes.GET_PROFESSIONAL_SETTINGS, getProfessionalSettings],
    [ActionTypes.DO_SAVE_PROFESSIONAL_SETTINGS, doSaveProfessionalSettings],
];

const getTimelineState = (state) => state.timeline;

function * getProfessionals ({ payload }) {
    yield put(Actions.loadingProfessionals());

    let timeline = yield select(getTimelineState);
    let selectedTimelineId = timeline.selectedTimelineId;
    if (!selectedTimelineId) {
        let loadedTimelines = yield call(loadTimelines);
        const timeline = _.first(loadedTimelines.data);
        selectedTimelineId = timeline.Id;
    }

    const response = yield call(loadProfessionals, { TimelineId: selectedTimelineId });

    if (!response.successful) {
        yield put(Actions.handleErrors(response));
    } else {
        yield put(Actions.loadedProfessionals(response.data));
    }
}

function * getProfessionalsCategories () {
    yield put(Actions.loadingProfessionalsCategories());
    const response = yield call(loadProfessionalsCategories);
    if (!response.successful) {
        yield put(Actions.handleErrors(response));
    } else {
        yield put(Actions.loadedProfessionalsCategories(response.data));
    }
}

function * getProfessionalDetails ({ payload }) {
    yield put(Actions.loadingProfessionalDetails());
    const response = yield call(loadProfessionalDetails, payload);
    if (!response.successful) {
        yield put(Actions.handleErrors(response));
    } else {
        yield put(Actions.loadedProfessionalDetails(response.data));
    }
}

function *  doSaveProfessionalDetails ({ payload }) {
    yield put(Actions.doingSaveProfessionalDetails());
    const response = yield call(saveProfessionalDetails, payload);
    if (!response.successful) {
        yield put(Actions.handleErrors(response));
    } else {
        yield put(Actions.doneSaveProfessionalDetails(response.data));
    }
}

function * getProfessionalSettings ({ payload }) {
    yield put(Actions.loadingProfessionalSettings());
    const response = yield call(loadProfessionalSettings);
    if (!response.successful) {
        yield put(Actions.handleErrors(response));
    } else {
        yield put(Actions.loadedProfessionalSettings(response.data));
    }
}

function * doSaveProfessionalSettings ({ payload }) {
    yield put(Actions.doingSaveProfessionalSettings());
    console.log(payload)
    const response = yield call(saveProfessionalSettings, payload);
    if (!response.successful) {
        yield put(Actions.handleErrors(response));
    } else {
        yield put(Actions.doneSaveProfessionalSettings(response.data));
    }
}