import React from "react";
import { Col, Nav } from "react-bootstrap";
import Link from "../../utils/Link";
import classNames from "classnames";
import profileIcon from "../icons/window-paragraph-3.png";
import ReviewsIcon from "../icons/f-chat-4.png";
import MessagesIcon from "../icons/b-comment-7.png";
import LeadsIcon from "../icons/contacts-44-4.png";
import ClientsIcon from "../icons/handshake-4.png";

const NavLink = ({ label, icon, disabled, path, currentPath, target, onClick }) => {

  const getIcon = () => {

    if (!icon) {
      return null;
    }

    switch (icon) {
      case "profile":
        return <img className={"png-icon"} src={profileIcon} />;
      case "leads":
        return <img className={"png-icon"} src={LeadsIcon} />;
      case "clients":
        return <img className={"png-icon"} src={ClientsIcon} />;
      case "reviews":
        return <img className={"png-icon"} src={ReviewsIcon} />;
      case "messages":
        return <img className={"png-icon"} src={MessagesIcon} />;

      default:
        return <img className={"icon icon-" + icon} />;
    }
  };

  if (!currentPath) {
    return <span
      onClick={onClick}
      className="nav-link"
    >
      <Col className={"sidebar__icon-container"}>{getIcon()}</Col>
      <Col className={"sidebar__label-container"}>{label}</Col>
    </span>
  }

  return (
    <Link
      onClick={onClick}
      target={target}
      to={path}
      classes={classNames(
        "nav-link",
        { disabled: disabled },
        { active: currentPath === path }
      )}
    >
      <Col className={"sidebar__icon-container"}>{getIcon()}</Col>
      <Col className={"sidebar__label-container"}>{label}</Col>
    </Link>
  );
};

export default NavLink;
