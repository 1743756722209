import { ActionTypes } from '../actions';
import _ from 'underscore';

const defaultState = {
    isLoading: true,
    isProfessionalDetailsLoading: true,
    isProfessionalSettingsLoading: true,
    isCategoriesLoading: true,
    professionals: [],
    professionalDetails: {}
};

export default (state = defaultState, action) => {
    try {
        switch(action.type) {
            case ActionTypes.LOADING_PROFESSIONALS:
                return {
                    ...state,
                    isLoading: true
                };

            case ActionTypes.LOADED_PROFESSIONALS:
                return {
                    ...state,
                    professionals: action.payload,
                    isLoading: false
                };

            case ActionTypes.DOING_HIRE_PROFESSIONAL:
                return {
                    ...state,
                    hiringProfessionalId: action.payload
                };

            case ActionTypes.DONE_HIRE_PROFESSIONAL:
                return {
                    ...state,
                    hiringProfessionalId: null,
                    professionals: [
                        ...state.professionals.map(category => {
                            if (category.CategoryId !== action.payload.CategoryId) {
                                return category;
                            }

                            return {
                                ...category,
                                HiredOn: action.payload.HiredOn,
                                HiredProfessionalExternalId: action.payload.HiredProfessionalExternalId,
                                HiredProfessionalId: action.payload.HiredProfessionalId,
                                ProfessionalExternal: action.payload.ProfessionalExternal,
                            }
                        })
                    ]
                };

            case ActionTypes.LOADING_PROFESSIONAL_DETAILS:
                return {
                    ...state,
                    isProfessionalDetailsLoading: true
                };

            case ActionTypes.LOADING_PROFESSIONALS_CATEGORIES:
                return {
                    ...state,
                    isCategoriesLoading: true
                };

            case ActionTypes.LOADED_PROFESSIONALS_CATEGORIES:
                return {
                    ...state,
                    isCategoriesLoading: false,
                    professionalsCategories: action.payload
                };

            case ActionTypes.LOADED_PROFESSIONAL_DETAILS:
                return {
                    ...state,
                    isProfessionalDetailsLoading: false,
                    professionalDetails: action.payload
                };

            case ActionTypes.DOING_SAVE_PROFESSIONAL_DETAILS:
                return {
                    ...state,
                    isProfessionalDetailsSaving: true,
                };

            case ActionTypes.DONE_SAVE_PROFESSIONAL_DETAILS:
                return {
                    ...state,
                    isProfessionalDetailsSaving: false,
                    professionalDetails: action.payload
                };

            case ActionTypes.LOADING_PROFESSIONAL_SETTINGS:
                return {
                    ...state,
                    isProfessionalSettingsLoading: true,
                };

            case ActionTypes.LOADED_PROFESSIONAL_SETTINGS:
                return {
                    ...state,
                    isProfessionalSettingsLoading: false,
                    professionalSettings: action.payload
                };

            case ActionTypes.DOING_SAVE_PROFESSIONAL_SETTINGS:
                return {
                    ...state,
                    isProfessionalSettingsSaving: true
                };

            case ActionTypes.DONE_SAVE_PROFESSIONAL_SETTINGS:
                return {
                    ...state,
                    isProfessionalSettingsSaving: false,
                    professionalSettings: action.payload
                };

            case ActionTypes.DOING_SAVE_STRIPE_CUSTOMER:
                return {
                    ...state,
                    isStripeCustomerSaving: true
                };

            case ActionTypes.DONE_SAVE_STRIPE_CUSTOMER:
                return {
                    ...state,
                    isStripeCustomerSaving: false,
                    professionalSettings: action.payload
                };


            default:
                return state;
        }
    } catch(error) {
            console.error(`Error in professionals reducer: ${ error.message || error.code || error }`, error);
        }
    };
