import React, { Component } from 'react';
import Profile from "../pages/Profile";
import { connect } from "react-redux";
import { Actions } from "../actions";
import Loader from "../utils/Loader";

class ProfileContainer extends Component {

  render() {
    const {
      isProfessionalDetailsLoading,
      isCategoriesLoading,
      professionalDetails
    } = this.props;

    if (isProfessionalDetailsLoading || isCategoriesLoading) {
      return <Loader />
    }

    return <Profile
      saveProfessional={this.props.doSaveProfessionalDetails}
      {...this.props}
    />;
  }
}

export default connect(state => state.professionals, Actions)(ProfileContainer);
