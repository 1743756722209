import React, {Component} from 'react';
import Reviews from "../pages/Reviews";

class ReviewsContainer extends Component {
    render() {
        return <Reviews />;
    }
}

export default ReviewsContainer;
