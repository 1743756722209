import React, { useState } from "react";
import PropTypes from 'prop-types'
import { Row, Col, Button } from "react-bootstrap";
import classNames from "classnames"
import moment from "moment"

import Card from "../Card"
import HomebuyerModal from "../HomebuyerModal"
import styles from './HomebuyerCard.module.scss'

export default function HomebuyerCard({ className, professionalDetails, professionalsCategories, ...other }) {
  const {
    PhotoLink,
    FirstName,
    LastName,
    CompanyName,
    Address,
    Email,
    Phone,
    WorkingSince,
    NoPhysicalAddress,
    DisplayContact
  } = professionalDetails

  const [open, setOpen] = useState(false)

  const toggleOpen = () => {
    setOpen(!open)
  }

  const fallBackImg = "https://netstock-europe.com/wp-content/uploads/2016/09/fg-avatar-anonymous-user-retina-300x300.png"

  return <Card className={className} {...other}>
    <HomebuyerModal
      show={open}
      handleClose={toggleOpen}
      professionalDetails={professionalDetails}
      categories={professionalsCategories}
    />
    <Button
      variant="primary"
      className={classNames("position-absolute", styles.moreButton)}
      onClick={toggleOpen}
    >
      Full profile
    </Button>
    <Row>
      <Col md="auto text-center mb-4 mb-md-0">
        <img src={professionalDetails.PhotoLink || fallBackImg} alt="" className={styles.profilePic} />
      </Col>
      <Col md="auto" xs={12} className={classNames("flex-md-grow-1 flex-md-shrink-1 text-center text-md-left", styles.content)}>
        <h6 className="mb-0">{FirstName && LastName && DisplayContact ? `${FirstName} ${LastName}` : CompanyName}</h6>
        <p className="mb-2">{FirstName && LastName && DisplayContact ? CompanyName : " "}</p>
        <div className="d-flex align-items-center mb-4 justify-content-center justify-content-md-start">
          {
            moment(WorkingSince)._isValid &&
            <p className="mb-0"><span>Experience: </span>{moment(WorkingSince).fromNow().slice(0,-3)}</p>
          }
        </div>
        {
          !NoPhysicalAddress&&
          <div className="d-flex align-items-center mb-2 justify-content-center justify-content-md-start">
            <i className="icon icon-pin mr-2" />
            <p className="mb-0">{Address||"[Address]"}</p>
          </div>
        }
        <div className="d-flex align-items-center mb-0 justify-content-center justify-content-md-start">
          <p className="mb-0"><span>No reviews yet: </span>★★★★★</p>
        </div>
        {/*<div className="d-flex align-items-center mb-2 justify-content-center justify-content-md-start">
          <i className="icon icon-pin mr-2" />
          <p className="mb-0">{Address}</p>
        </div>
        <div className="d-flex align-items-center justify-content-center justify-content-md-start">
          <i className="icon icon-mail mr-2" />
          <p className="mb-0">{Email}</p>
          <i className="icon icon-phone mr-2 ml-4" />
          <p className="mb-0">{Phone}</p>
        </div>*/}
      </Col>
    </Row>
  </Card>
}

HomebuyerCard.propTypes = {
  className: PropTypes.string,
  professionalDetails: PropTypes.object,
  professionalsCategories: PropTypes.array
}
